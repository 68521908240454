<template>
  <div class="file_list_wrapper">
    <div class="item"
      v-for="(item, index) in fileList"
      :key="index"
      @click="handleClick(item.downloadUrl)">
      <div class="el-icon-document"></div>
      <div class="name">{{item.originName}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick (url) {
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.file_list_wrapper {
  width: 100%;
  padding: 0 5px;
  .item {
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #606266;
    font-size: 14px;
    .name {
      margin-left: 5px;
    }
  }
}
</style>
