<template>
  <el-dialog
    title="提示"
    :visible.sync="$_visible"
    width="500px">
    <span v-if="dialogType === 0">确认通过</span>
    <span v-else>是否驳回</span>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        v-if="dialogType === 0"
        type="primary"
        size="small"
        :loading="passBtn"
        @click="passTask">
        通过任务
      </el-button>
      <el-button
        v-else
        size="small"
        type="primary"
        :loading="rejectBtn"
        @click="rejectTask">
        驳回
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    // 0通过任务，1驳回任务
    dialogType: {
      type: Number,
      default: 0
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    }
  },
  data () {
    return {
      passBtn: false,
      rejectBtn: false
    }
  },
  methods: {
    // 通过任务
    passTask () {
      this.passBtn = true
      api.adminPassTask(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.$router.push('/admin/manager')
        } else {
          this.$message.error('操作失败')
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.passBtn = false
        this.closeDialog()
      })
    },
    // 驳回任务
    rejectTask () {
      api.adminRejectTask(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.$router.push('/admin/manager')
        } else {
          this.$message.error('操作失败')
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.passBtn = false
        this.closeDialog()
      })
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>

<style>

</style>
