<template>
  <div class="audit_detail_container">

    <!-- 标题 -->
    <div class="title_container">
      <div class="back_to_task" @click="backTo">
        <i class="el-icon-arrow-left"></i>
        <span class="tw-ml-[4px]">返回上一页</span>
      </div>
      <div class="task_title">任务审核</div>
    </div>

    <!-- 表单容器 -->
    <div class="task_allinfo_container">
      <div class="task_item">
        <div class="task_title">任务标题</div>
        <div class="info">{{descriptionList.title}}</div>
      </div>
      <div class="task_item">
        <div class="task_title">任务类型</div>
        <div class="info">
          {{
            {
              0: '单人任务'
            }[descriptionList.type]
          }}
        </div>
      </div>
      <div class="task_item">
        <div class="task_title">任务奖励</div>
        <div class="info">{{descriptionList.amount}}元</div>
      </div>
      <div class="task_item">
        <div class="task_title">任务截止时间</div>
        <div class="info">{{descriptionList.deadline}}</div>
      </div>
      <div class="task_item">
        <div class="task_title">任务时限</div>
        <div class="info">{{descriptionList.timeLimit}}天</div>
      </div>
      <div class="task_item">
        <div class="task_title">任务级别</div>
        <span
          :style="{
            [TASK_LEVEL.COMMON]: 'color: #67c23a;',
            [TASK_LEVEL.GENERAL]: 'color: #e6a23c;',
            [TASK_LEVEL.COMPLEX]: 'color: #f56c6c;',
            [TASK_LEVEL.DIFFICULTY]: 'color: #909399;'
          }[descriptionList.level]">
          {{
            {
              [TASK_LEVEL.COMMON]: '普通',
              [TASK_LEVEL.GENERAL]: '一般',
              [TASK_LEVEL.COMPLEX]: '复杂',
              [TASK_LEVEL.DIFFICULTY]: '困难'
            }[descriptionList.level]
          }}
        </span>
      </div>
      <div class="task_item">
        <div class="task_title">描述内容</div>
        <div
          class="info"
          style="width: 600px;"
          v-html="descriptionList.remark">
        </div>
      </div>
      <div class="task_item">
        <div class="task_title">任务要求</div>
        <div
          class="info"
          style="width: 600px;"
          v-html="descriptionList.require">
        </div>
      </div>
      <div class="task_item">
        <div class="task_title">相关附件</div>
        <file-list
          :fileList="descriptionList.attachments"
          style="width: 370px;">
        </file-list>
      </div>
    </div>

    <!-- 按钮 -->
    <div class="task_audit">
      <el-button
        size="small"
        type="primary"
        @click="handlePassTask">
        通过任务
      </el-button>
      <el-button
        size="small"
        @click="handleRejectTask">
        驳回
      </el-button>
    </div>

    <!-- 通过/驳回任务弹窗 -->
    <audit-dialog
      :visible.sync="visible"
      :dialogType="dialogType">
    </audit-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import AuditDialog from '../components/audit-dialog.vue'
import FileList from '@/components/upload/file-list.vue'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    AuditDialog,
    FileList
  },
  data () {
    return {
      visible: false,
      dialogType: 0,
      descriptionList: {},
      loading: false
    }
  },
  computed: {
    TASK_LEVEL () {
      return globalVar.TASK_LEVEL
    },
    taskId () {
      return this.$route.params.taskId
    }
  },
  created () {
    this.getTaskDetail()
  },
  methods: {
    backTo () {
      this.$router.push('/admin/manager')
    },
    handlePassTask () {
      this.dialogType = 0
      this.visible = true
    },
    handleRejectTask () {
      this.dialogType = 1
      this.visible = true
    },
    // 获取任务详情
    getTaskDetail () {
      this.loading = true
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.descriptionList = res.data.data
        } else {
          this.$message.error('获取信息失败')
        }
      }).catch(() => {
        this.$message.error('请求异常')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.audit_detail_container {
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  .title_container {
    width: 100%;
    height: 100px;
    min-height: 100px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E7ED;
    .back_to_task {
      width: 100px;
      height: 40px;
      font-size: 14px;
      margin-right: 42px;
      text-align: center;
      line-height: 40px;
      color: #606266;
      cursor: pointer;
    }
    .task_title {
      font-size: 20px;
      color: #303133;
      font-weight: bold;
    }
  }
  .task_allinfo_container {
    width: 100%;
    height: 100%;
    padding: 30px 50px 0 50px;
    overflow: auto;
    .task_item {
      display: flex;
      margin-bottom: 22px;
      word-wrap:break-word;
      word-break:break-all;
      .task_title {
        width: 100px;
        min-width: 100px;
        font-size: 14px;
        font-weight: 500;
        color: #909399;
      }
      .info {
        font-size: 14px;
        white-space: pre-line;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .task_audit {
    height: 60px;
    display: flex;
    align-items: center;
    border-top: 1px solid #E4E7ED;
    padding: 0 30px;
  }
}
</style>
